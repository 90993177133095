import gql from "graphql-tag";

export function UNINSTALL_SNIPPET() {
  return gql`
    mutation UNINSTALL_SNIPPET {
      removeSnippetResponse(input: $input)
        @rest(
          type: "UninstallSnippet"
          path: "/uninstallSnippets"
          method: "POST"
        ) {
        Installed
      }
    }
  `;
}

export function SAVE_ONBOARDING_STATUS() {
  return gql`
    mutation SAVE_ONBOARDING_STATUS {
      onboardingStatus(input: $input)
        @rest(type: "SaveOnboardingStatus", path: "/onboarding/status", method: "POST") {
          NoResponse
      }
    }
  `;
}

export function SAVE_COUNTDOWN_SETTINGS(appName) {
  return gql`
    mutation SAVE_SETTINGS {
      removeSnippetResponse(input: $input)
        @rest(type: "CountdownTimerSettings", path: "/${appName}/settings/countdowntimer", method: "POST") {
          NoResponse
      }
    }
  `;
}

export function SET_FEEDBACK() {
  return gql`
    mutation SET_FEEDBACK($feedback: String) {
      setFeedbackResponse(feedback: $feedback, input: {})
        @rest(type: "SetFeedback", path: "/onboarding/feedback/{args.feedback}", method: "POST") {
          NoResponse
      }
    }
  `;
}

export function SAVE_APP_SETTINGS(appName) {
  return gql`
    mutation SAVE_SETTINGS {
      saveResponse(input: $input)
        @rest(type: "AppSettings", path: "/${appName}/settings", method: "POST") {
          Successful
          ErrorMessage
      }
    }
  `;
}

export function SAVE_ANNOUNCEMENT_SETTINGS(appName) {
  return gql`
    mutation SAVE_SETTINGS {
      removeSnippetResponse(input: $input)
        @rest(type: "CountdownTimerSettings", path: "/${appName}/settings/announcement", method: "POST") {
          NoResponse
      }
    }
  `;
}
