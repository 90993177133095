import React, { useEffect, useState } from "react";
import {
  LegacyCard,
  Button,
  ProgressBar,
  ButtonGroup,
  Popover,
  ActionList,
  Icon,
  Tooltip,
  Text,
  Divider,
  BlockStack,
  Box,
  InlineStack
} from "@shopify/polaris";
import {
  HorizontalDotsMinor,
  ChevronDownMinor,
  ChevronUpMinor,
  CircleTickMajor,
  MobileCancelMajor,
  ChatMajor
} from "@shopify/polaris-icons";
import "./OnboardingComponent.css";

const OnboardingComponent = ({
  title = "",
  description = "",
  steps = [],
  setStepCompletion = (index) => {},
  setSteps = () => {},
  onDismiss = () => {},
  onSupport = () => {},
}) => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [isPopoverActive, setIsPopoverActive] = useState(false);
  const [expandedStep, setExpandedStep] = useState(null);
  const completedStepsCount = steps.filter((step) => step.completed).length;

  useEffect(() => {
    if (completedStepsCount === steps.length) {
      setIsCollapsed(true);
    }
    else {
      const firstIncompleteStep = steps.findIndex((step) => !step.completed);
      setExpandedStep(
        firstIncompleteStep !== -1 ? firstIncompleteStep : completedStepsCount,
      );
    }
  }, [steps, completedStepsCount]);

  return (
    <LegacyCard>
      <LegacyCard.Header title={title}>
        <ButtonGroup>
          <Popover
            active={isPopoverActive}
            activator={
              <Button
                icon={HorizontalDotsMinor}
                onClick={() => {
                  setIsPopoverActive(!isPopoverActive);
                }}
                variant="plain"
              ></Button>
            }
            onClose={() => {
              setIsPopoverActive(false);
            }}
          >
            <ActionList
              items={[
                { content: "Dismiss", onAction: onDismiss, icon: MobileCancelMajor },
                { content: "Get support", onAction: onSupport, icon: ChatMajor },
              ]}
            />
          </Popover>
          <Button
            onClick={() => setIsCollapsed(!isCollapsed)}
            icon={isCollapsed ? ChevronDownMinor : ChevronUpMinor}
            variant="plain"
          ></Button>
        </ButtonGroup>
      </LegacyCard.Header>
      <LegacyCard.Section>
        <BlockStack gap={400}>
          <Text variant="bodyMd" as="span">
            {description}
          </Text>
          <InlineStack blockAlign="center" wrap={false} gap={400}>
            <Text variant="bodyMd" as="span" tone="subdued">
              <div className="completed-steps">{completedStepsCount} of {steps.length} completed</div>
            </Text>
            <ProgressBar
                size="small"
                tone="success"
                progress={(completedStepsCount / steps.length) * 100}
              />
          </InlineStack>
        </BlockStack>
      </LegacyCard.Section>
      {!isCollapsed && (
        <LegacyCard.Section>
          <Divider />
          <Box paddingBlockStart="400">
            <BlockStack gap="200">
            {steps.map((step, index) => (
              <div key={index}>
                <div
                  className={
                    expandedStep === index
                      ? "onboarding-step expanded"
                      : "onboarding-step"
                  }
                >
                  {step.editable && (
                    <div
                      className="icon"
                      onClick={() => setStepCompletion(index)}
                    >
                      <Tooltip
                        content={`Mark as ${step.completed ? "not done" : "done"}`}
                        dismissOnMouseOut
                        preferredPosition="above"
                      >
                        {step.completed && <Icon source={CircleTickMajor} />}
                        {!step.completed && (
                          <span className="circle-empty"></span>
                        )}
                      </Tooltip>
                    </div>
                  )}
                  {!step.editable && (
                    <div className="icon">
                      {step.completed && <Icon source={CircleTickMajor} />}
                      {!step.completed && (
                        <span className="circle-empty"></span>
                      )}
                    </div>
                  )}
                  <div
                    className="content-and-media"
                    onClick={() => setExpandedStep(index)}
                  >
                    <div className="content-box">
                      <div className="heading">
                        {expandedStep !== index && <>{step.title}</>}
                        {expandedStep === index && <b>{step.title}</b>}
                      </div>
                      {expandedStep === index && (
                        <div className="content">
                          <p>{step.description}</p>
                        </div>
                      )}
                    </div>
                    {expandedStep === index && step.mediaSrc && (
                      <div className="media">
                        <img src={step.mediaSrc} alt={step.title} />
                        <div className="fade"></div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ))}
            </BlockStack>
          </Box>
        </LegacyCard.Section>
      )}
    </LegacyCard>
  );
};

export default OnboardingComponent;
