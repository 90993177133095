export const SubscriptionPlan = Object.freeze({
    Inactive: 0,
    LegacyStarter: 1,
    LegacyBuilder: 2,
    LegacyGrower: 3,
    LegacyScaler: 4,
    Starter: 5,
    Lite: 6,
    Professional: 7,
    Elite: 8,
    Unlimited: 100
  });

export const GetPlanName = (plan) => {
    switch (plan) {
        case SubscriptionPlan.LegacyBuilder:
            return "LegacyBuilder";
        case SubscriptionPlan.LegacyGrower:
            return "LegacyGrower";
        case SubscriptionPlan.LegacyScaler:
            return "LegacyScaler";
        case SubscriptionPlan.Unlimited:
            return "Unlimited";
        case SubscriptionPlan.Lite:
            return "Lite";
        case SubscriptionPlan.Professional:
            return "Professional";
        case SubscriptionPlan.Elite:
            return "Elite";
        case SubscriptionPlan.LegacyStarter:
            return "LegacyStarter";
        case SubscriptionPlan.Starter:
        default:
            return "Starter";
    }
}