import "typeface-alegreya-sc";
import "typeface-amatic-sc";
import "typeface-aleo";
import "typeface-anton";
import "typeface-arvo";
import "typeface-baloo";
import "typeface-bubblegum-sans";
import "typeface-chewy";
import "typeface-cormorant";
import "typeface-courgette";
import "typeface-creepster";
import "typeface-dancing-script";
import "typeface-exo";
import "typeface-julius-sans-one";
import "typeface-karla";
import "typeface-kaushan-script";
import "typeface-libre-franklin";
import "typeface-lilita-one";
import "typeface-mr-dafoe";
import "typeface-neuton";
import "typeface-open-sans";
import "typeface-pacifico";
import "typeface-parisienne";
import "typeface-playfair-display";
import "typeface-righteous";
import "typeface-roboto";
import "typeface-roboto-mono";
import "typeface-sacramento";
import "typeface-space-mono";
import "typeface-spectral";
import "typeface-wire-one";
import React, { Component } from "react";
import Select from "react-select";
import { Label } from "@shopify/polaris";

const fonts = [
  { value: "", label: "Inherit" },
  { value: "Aleo", label: "Aleo" },
  { value: "Alegreya SC", label: "Alegreya SC" },
  { value: "Amatic SC", label: "Amatic SC" },
  { value: "Anton", label: "Anton" },
  { value: "Arvo", label: "Arvo" },
  { value: "Baloo", label: "Baloo" },
  { value: "Bubblegum Sans", label: "Bubblegum Sans" },
  { value: "Chewy", label: "Chewy" },
  { value: "Consolas", label: "Consolas" },
  { value: "Cormorant", label: "Cormorant" },
  { value: "Courgette", label: "Courgette" },
  { value: "Creepster", label: "Creepster" },
  { value: "Dancing Script", label: "Dancing Script" },
  { value: "Exo", label: "Exo" },
  { value: "Julius Sans One", label: "Julius Sans One" },
  { value: "Karla", label: "Karla" },
  { value: "Kaushan Script", label: "Kaushan Script" },
  { value: "Lato", label: "Lato" },
  { value: "Libre Franklin", label: "Libre Franklin" },
  { value: "Lilita One", label: "Lilita One" },
  { value: "Mr Dafoe", label: "Mr Dafoe" },
  { value: "Neuton", label: "Neuton" },
  { value: "Open Sans", label: "Open Sans" },
  { value: "Pacifico", label: "Pacifico" },
  { value: "Parisienne", label: "Parisienne" },
  { value: "Playfair Display", label: "Playfair Display" },
  { value: "Righteous", label: "Righteous" },
  { value: "Roboto", label: "Roboto" },
  { value: "Roboto Mono", label: "Roboto Mono" },
  { value: "Sacramento", label: "Sacramento" },
  { value: "Space Mono", label: "Space Mono" },
  { value: "Spectral", label: "Spectral" },
  { value: "Wire One", label: "Wire One" }
];

export default class FontPicker extends Component {
  static defaultProps = {
    onChange: value => null,
    label: "Font",
    position: 0,
    value: null
  };

  colorStyles = {
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        fontFamily: data.value,
        fontSize: "16pt"
      };
    },
    singleValue: (styles, { data }) => ({ ...styles, fontFamily: data.value ? data.value : "inherit" }),
    container: (base, state) => ({
      ...base,
      opacity: state.isDisabled ? ".5" : "1",
      backgroundColor: "transparent",
      zIndex: 10000-this.props.position,
      position: "relative"
    }),
    menu: (base, state) => ({
      ...base,
      zIndex: 10000-this.props.position
    })
  };

  getMenuPortalTarget = () => {
    const modalSection = document.querySelector('.Polaris-Modal-Section');
    return modalSection || document.body;
  };

  render() {
    return (
      <div>
        <div class="Polaris-Labelled__LabelWrapper">
          <Label>{this.props.label}</Label>
        </div>
        <Select
          defaultValue={fonts[5]}
          label="Select font"
          options={fonts}
          styles={this.colorStyles}
          isSearchable={false}
          value={{ label: this.props.value ? this.props.value : fonts[0].label, value: this.props.value }}
          onChange={opt => this.props.onChange(opt.value)}
          menuPortalTarget={this.getMenuPortalTarget()}
          menuPosition="fixed"
        />
      </div>

    );
  }
}
