export const capitalize = s => {
  if (typeof s !== "string") return "";
  return s.charAt(0).toUpperCase() + s.slice(1);
};

export const camelCase = s => {
  if (typeof s !== "string") return "";
  return s.charAt(0).toLowerCase() + s.slice(1);
};

export const prettyKey = s => {
  let replaceUnderscore = s.replace("_", " ").replace(" id", "");
  return capitalize(replaceUnderscore);
};

export const prettyValue = s => {
  let replaceAsterisk = s.replace("*", "");
  return capitalize(replaceAsterisk);
};

export const capitalizeKeys = obj => {
  if(!obj)
    return obj;
  
  let newObj = {};
    
  Object.keys(obj).forEach(function (key) {
    var k = capitalize(key);
    
    if (k !== key) {
      newObj[k] = obj[key];
    }
  });
  return (newObj);
};

export const camelCaseKeys = obj => {
  if(!obj)
    return obj;

  let newObj = {};

  Object.keys(obj).forEach(function (key) {
    var k = camelCase(key);
    
    if (k !== key) {
      newObj[k] = obj[key];
    }
  });
  return (newObj);
};