import React from 'react';
import {
    Page,
    Layout,
    Card,
    SkeletonBodyText,
    SkeletonDisplayText,
    TextContainer,
    BlockStack
} from '@shopify/polaris';

const WidgetDesignSkeletonPage = ({ title = "" }) => {
    return (
        <Page
            title={title}
            primaryAction={{ content: 'Save', disabled: true }}
            backAction={{
                content: 'Back',
                onAction: () => {}
            }}
        >
            <Layout>
                <Layout.Section variant="oneThird">
                    <BlockStack gap={400}>
                        <Card>
                            <TextContainer>
                                <SkeletonDisplayText size="small" />
                                <SkeletonBodyText lines={2} />
                            </TextContainer>
                        </Card>
                        <Card>
                            <TextContainer>
                                <SkeletonDisplayText size="small" />
                                <SkeletonBodyText lines={8} />
                            </TextContainer>
                        </Card>
                        <Card>
                            <TextContainer>
                                <SkeletonDisplayText size="small" />
                                <SkeletonBodyText lines={8} />
                            </TextContainer>
                        </Card>
                        <Card>
                            <TextContainer>
                                <SkeletonDisplayText size="small" />
                                <SkeletonBodyText lines={4} />
                            </TextContainer>
                        </Card>
                        <Card>
                            <TextContainer>
                                <SkeletonDisplayText size="small" />
                                <SkeletonBodyText lines={4} />
                            </TextContainer>
                        </Card>
                    </BlockStack>
                </Layout.Section>
                <Layout.Section variant="oneHalf">
                    <Card>
                        <BlockStack gap={200}>
                            <TextContainer>
                                <SkeletonDisplayText size="small" />
                                <SkeletonBodyText lines={2} />
                            </TextContainer>
                        </BlockStack>
                    </Card>
                </Layout.Section>
            </Layout>
        </Page>
    );
};

export default WidgetDesignSkeletonPage;
