import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import Config from "./utils/Config";
import { camelCaseKeys } from "./utils/StringUtils";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { SubscriptionPlan } from "./pages/SubscriptionPage/SubscriptionPlan";
import axios from "axios";
import * as serviceWorker from "./serviceWorker";

const authQueryString = new URLSearchParams(window.location.search).toString();
const installUrl = `${Config.flyApi.URL}/install/${Config.appName}?${authQueryString}`;

Sentry.init({
  dsn: Config.sentryDsn,
  integrations: [new BrowserTracing()],

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
});

const authenticate = async () => {
  try {
    const { data, status } = await axios.get(installUrl);

    if (status === 200) {
      const { OnboardingStatus, Plan, FreeDiscountsLeft, NeedsVatNumber, MissingScopes, RedirectUrl } = data;
      const commonProps = {
        plan: Plan,
        freeDiscountsLeft: FreeDiscountsLeft,
        requestVatNumber: NeedsVatNumber,
        missingScopes: MissingScopes,
        onboardingStatus: camelCaseKeys(OnboardingStatus),
      };
      
      if (Plan >= SubscriptionPlan.LegacyStarter) {
        ReactDOM.render(<App {...commonProps} />, document.getElementById("root"));
      } else {
        window.top === window.self
          ? window.location.assign(RedirectUrl)
          : ReactDOM.render(<App {...commonProps} redirectUrl={RedirectUrl} />, document.getElementById("root"));
      }
    } else {
      throw new Error(`Unexpected status code: ${status}`);
    }
  } catch (error) {
    console.error("Authentication failed:", error);
    document.getElementById("root").innerText = "Authentication failed, please try again";
  }
};

if (!authQueryString) {
  document.getElementById("root").innerText = "Cannot authenticate, please reload app";
} else {
  authenticate();
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
