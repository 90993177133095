import React, { useState, useEffect } from 'react';
import { BlockStack, Button, Card, InlineStack, Text } from '@shopify/polaris';
import {
  ChevronLeftMinor,
  ChevronRightMinor
} from "@shopify/polaris-icons";
import './CarouselCard.css';

const partners = [
  { id: 1, name: 'Disco: Flash Sales & Discounts', image: 'https://cdn.shopify.com/app-store/listing_images/5d32c48dd750cd6fa13852711464267e/icon/COeL5Mf0lu8CEAE=.png', description: 'Create bulk discounts, storewide sale, timers & landing pages', link: 'https://apps.shopify.com/discount-app' },
  { id: 2, name: 'PageFly Landing Page Builder', image: 'https://cdn.shopify.com/app-store/listing_images/f85ee597169457da8ee70b6652cae768/icon/CKmsycCOx_YCEAE=.png', description: 'Landing page builder, home, product page, store section design ', link: 'https://apps.shopify.com/discount-app' },
  { id: 3, name: 'Klaviyo: Email Marketing & SMS ', image: 'https://cdn.shopify.com/app-store/listing_images/5edd9000b933a8fa88c152d1e498531f/icon/CP6B2OOv3PYCEAE=.png?height=72&width=72 1x, https://cdn.shopify.com/app-store/listing_images/5edd9000b933a8fa88c152d1e498531f/icon/CP6B2OOv3PYCEAE=.png?height=144&width=144 2x', description: 'Power smarter digital relationships with email marketing & SMS', link: 'https://apps.shopify.com/discount-app' },
  { id: 4, name: 'Printful: Print on Demand', image: 'https://cdn.shopify.com/app-store/listing_images/3dd87416a3b1e12a9ef3604aaaaf4d1e/icon/CNDmhPSW_oQDEAE=.jpeg', description: 'Sell custom print and embroidery items with no upfront costs', link: 'https://apps.shopify.com/discount-app' },
  { id: 5, name: 'Partner 5', image: 'https://www.claudeusercontent.com/api/placeholder/48/48', description: 'Description for Partner 5', link: 'https://apps.shopify.com/discount-app' },
];

const CarouselCard = ({ title }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [partnersToShow, setPartnersToShow] = useState(2);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 1024) {
        setPartnersToShow(4);
      } else if (window.innerWidth >= 768) {
        setPartnersToShow(3);
      } else {
        setPartnersToShow(2);
      }
    };

    handleResize(); // Set initial value
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);


  const rotateLeft = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  const rotateRight = () => {
    if (currentIndex < partners.length - partnersToShow) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  const isAtStart = currentIndex === 0;
  const isAtEnd = currentIndex === partners.length - partnersToShow;

  return (
    <Card>
      <BlockStack gap={400}>
        <Text variant="headingMd" as="p">{title}</Text>
        <div className="partner-carousel">
          <div
            className="partner-carousel-inner"
            style={{ transform: `translateX(-${currentIndex * (100 / partnersToShow)}%)` }}
          >
            {partners.map((partner, index) => (
              <div 
                key={partner.id} 
                className={`partner-item ${index === currentIndex + partnersToShow ? 'partner-item-faded' : ''}`}
              >
                <Card>
                  <BlockStack gap={200}>
                    <InlineStack align="start" blockAlign="center" gap={200} wrap={false}>
                      <img src={partner.image} alt={partner.name} className="partner-image" />
                      <Text variant="headingMd" as="span" tone="subdued">{partner.name}</Text>
                    </InlineStack>
                    <Text variant="bodyMd" as="span" tone="subdued">{partner.description}</Text>
                    <Button variant="secondary" target="_blank" url={partner.link}>Install</Button>
                  </BlockStack>
                </Card>
              </div>
            ))}
          </div>
        </div>
      </BlockStack>
      {!isAtStart && (
        <div className="carousel-button carousel-button-left">
          <Button icon={ChevronLeftMinor} size="slim" accessibilityLabel="Previous partner"  onClick={rotateLeft} />
        </div>
      )}
      {!isAtEnd && (
        <div className="carousel-button carousel-button-right">
          <Button icon={ChevronRightMinor} size="slim" accessibilityLabel="Next partner"  onClick={rotateRight} />
        </div>
      )}
    </Card>
  );
};

export default CarouselCard;