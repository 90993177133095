import React, { useState, useEffect } from 'react';
import { Page, Toast, Frame, Modal } from '@shopify/polaris';
import { useQuery, useMutation } from '@apollo/client';
import AnnouncementSettings from '../../features/AnnoncementBar/AnnouncementSettings';
import { GET_DESIGN_SETTINGS } from '../../graphql/Queries/Settings';
import { SAVE_ANNOUNCEMENT_SETTINGS } from "../../graphql/Mutations/Settings";
import { isEqual } from 'lodash';
import { ArrowLeftMinor } from '@shopify/polaris-icons';
import WidgetDesignSkeletonPage from './WidgetDesignSkeletonPage';

const AnnouncementBarPage = ({ 
  appName = "discount-planner-by-productessentials",
  dialog = false,
  open = false,
  onClose = () => {},
  onBack = () => {}
}) => {
  const { loading, data } = useQuery(GET_DESIGN_SETTINGS(appName), {
    fetchPolicy: "network-only",
  });

  const [settings, setSettings] = useState();
  const [initialSettings, setInitialSettings] = useState();
  const [toastProps, setToastProps] = useState({ content: '', error: false });
  const [isToastVisible, setIsToastVisible] = useState(false);
  const [saveSettings, { loading: saving }] = useMutation(SAVE_ANNOUNCEMENT_SETTINGS(appName));

  // Set settings when data loads
  useEffect(() => {
    if (data && data.settings && data.settings.AnnouncementBarSettings) {
      setSettings(data.settings.AnnouncementBarSettings);
      setInitialSettings(data.settings.AnnouncementBarSettings);
    }
  }, [data]);

  if (loading && !dialog) return <WidgetDesignSkeletonPage title="Announcement bar settings" />;

  const handleClose = () => {
    setSettings(initialSettings);
    onClose();
  };

  const handleSave = async (settingsToSave) => {
    try {
      await saveSettings({
        variables: {
          input: {
            Settings: settingsToSave,
          },
        },
      });
      setSettings(settingsToSave);
      setInitialSettings(settingsToSave);
      setToastProps({ content: 'Announcement settings saved', error: false });
      setIsToastVisible(true);
    } catch (err) {
      console.error(err);
      setToastProps({ content: `Error saving settings: ${err.message}`, error: true });
      setIsToastVisible(true);
    }
  };

  const dismissToast = () => setIsToastVisible(false);
  const saveDisabled = isEqual(initialSettings, settings);
  const content = (
    <>
      <AnnouncementSettings 
        settings={settings}
        onSettingsChange={setSettings}
        appName={appName}
        onShowToast={(content) => {
          setToastProps({ content, error: false });
          setIsToastVisible(true);
        }}
      />
      {isToastVisible && (
        <Toast
          content={toastProps.content}
          error={toastProps.error}
          onDismiss={dismissToast}
        />
      )}
    </>
  );

  if (dialog) {
    return (
      <Modal
        open={open}
        size="large"
        onClose={handleClose}
        title="Announcement bar settings"
        primaryAction={{
          content: 'Save',
          onAction: async () => { 
            await handleSave(settings); 
            onClose(); 
          },
          disabled: saveDisabled,
          loading: saving
        }}
      >
        <Modal.Section>{content}</Modal.Section>
      </Modal>
    );
  }

  return (
    <Frame>
      <Page 
        title="Announcement bar settings" 
        primaryAction={{ 
          content: 'Save', 
          onAction: () => handleSave(settings), 
          disabled: saveDisabled, 
          loading: saving 
        }}
        backAction={{
          content: 'Back',
          icon: ArrowLeftMinor,
          onAction: onBack
        }}
      >
        {content}
      </Page>
    </Frame>
  );
};

export default AnnouncementBarPage;
