export const buildAppEmbedDeepLink = (myshopifyDomain, uuid, handle, template = null) => {
  if (template)
    return `https://${myshopifyDomain}/admin/themes/current/editor?context=apps&template=${template}&activateAppId=${uuid}/${handle}`
  return `https://${myshopifyDomain}/admin/themes/current/editor?context=apps&activateAppId=${uuid}/${handle}`
};

export const buildAppBlockDeepLink = (myshopifyDomain, uuid, handle, target = "mainSection", template = "product") => {
  return `https://${myshopifyDomain}/admin/themes/current/editor?template=${template}&addAppBlockId=${uuid}/${handle}&target=${target}`
};

export const buildAppEmbedExpandDeepLink = (myshopifyDomain, uuid, handle) => {
  return `https://${myshopifyDomain}/admin/themes/current/editor?context=apps&appEmbed=${uuid}/${handle}`
}