/**
 * Creates string with CSS variable values.
 * @param {PositionTypes} position
 */
export function constructCssVars(settings, prefix) {
    let cssString = "";

    Object.entries(settings).forEach(([prop, value]) => {
        if (value !== null)
            cssString += `${getCssVarName(prop, prefix)}: ${formatCssValue(prop, value)};`
    });

    return cssString;
}

/**
 * Checks if a target string ends with the given string.
 * @param {*} str 
 * @param {*} target 
 * @returns 
 */
export function endsWith(str, target) {
    const regexObj = new RegExp(`${str}$`,"i");
    return regexObj.test(target);
}

/**
 * Formats the CSS value (adds px, pt or quotes).
 * @param {string} prop 
 * @param {string} value 
 * @returns 
 */
export function formatCssValue(prop, value) {
    if (!value) return value;

    const pixelProps = [
        "MarginTop", 
        "MarginBottom", 
        "MarginLeft", 
        "MarginRight", 
        "MarginElements", 
        "BoxMaxHeight", 
        "BoxMaxWidth", 
        "SizeFlipNumber", 
        "SizeFlipNumberMobile",
        "SizeCornerRadius", 
        "SizeFlipCornerRadius", 
        "SizeFlipLabelText",
        "SizeFlipLabelTextMobile",
        "SizeText",
        "SizeTextMobile",
        "SizeTextButton",
        "SizeTextButtonMobile"
    ];

    const pointProps = ["SizeHeading"];
    const stringProps = [
        "ContentDays",
        "ContentHours",
        "ContentMinutes",
        "ContentSeconds",
        "ContentHeading"
    ];

    if (pixelProps.find(x => x === prop) || endsWith("Px", prop)) 
        return value + "px";
    if (pointProps.find(x => x === prop)) return value + "pt";
    if (stringProps.find(x => x === prop)) return `"${value}"`;

    return value;
}

/**
 * Formats a CSS variable name based on propname and prefix.
 * @param {string} propName 
 * @param {string} prefix 
 * @returns 
 */
export function getCssVarName(propName, prefix) {
    const prefixFormatted = prefix ? `${prefix}-` : "";
    return (`--${prefixFormatted}` + propName.split(/(?=[A-Z])/).join("-")).toLowerCase();
}

/**
 * Formats a CSS class name based on propname and prefix.
 */
export function getClassName(propName, prefix = "") {
    const prefixFormatted = prefix ? `${prefix}-` : "";
    return (prefixFormatted + propName.split(/(?=[A-Z])/).join("-")).toLowerCase();
}

/**
 * Creates string with CSS class names.
 * @param {PositionTypes} position
 */
export function constructCssClasses(settings) {
    const classes = Object.entries(settings).map(([prop, value]) => {
        if (value !== null && value === true || value === 'true')
            return getClassName(prop);
    });
    return classes.filter(x => x);
}

/**
 * Converts an enum value to a CSS class name representing that value.
 * @param {string} prop 
 * @param {object} enumObject 
 * @param {integer} enumValue 
 * @param {string} prefix 
 * @returns 
 */
export function convertEnumValueToClassName(propName, enumObject, enumValue, prefix = "pe") {
    const enumValues = Object.entries(enumObject);
    for (let i = 0; i < enumValues.length; i++) {
        const [enumName, value] = enumValues[i];
        if (value === enumValue) {
            return getClassName(propName + enumName, prefix);
        }
    }
    return "";
}