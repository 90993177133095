const dev = {
    apiGateway: {
      URL: "https://api.byproductessentials.com/dev"
    },
    flyApi: {
      URL: "https://productessentials-api-dev.fly.dev"
    },
    appName: "disco-dev",
    apiKey: "5f4ae45257c4779d8526080dd6291132",
    sentryDsn: "https://9afbbe804e604502b2fd765346ce8bd1@o302454.ingest.sentry.io/4504139899338752",
    appEmbedUuid: "a65575de-72bd-46c3-a21f-5b009e85bc92"
  };
  
  const prod = {
    apiGateway: {
      URL: "https://api.byproductessentials.com/prod"
    },
    flyApi: {
      URL: "https://productessentials-api.fly.dev"
    },
    appName: "discount-planner-by-productessentials",
    apiKey: "5d32c48dd750cd6fa13852711464267e",
    sentryDsn: "https://9afbbe804e604502b2fd765346ce8bd1@o302454.ingest.sentry.io/4504139899338752",
    appEmbedUuid: "7d4e9f1f-7c53-469f-baff-26fef3ed7c4f"
  };
  
  const config = process.env.REACT_APP_STAGE === 'prod'
    ? prod
    : dev;
  
  export default {
    // Add common config values here
    MAX_ATTACHMENT_SIZE: 5000000,
    ...config
  };
