export const setObject = (key, value) => {
  try {
    localStorage.setItem(key, JSON.stringify(value));
  } catch (error) {
    console.error("Set " + key + " failed due to " + error)
  }
};

export const getObject = (key) => {
  try {
    var value = localStorage.getItem(key);
    return value && JSON.parse(value);
  } catch (error) {
    console.error("Get " + key + " failed due to " + error)
    return null;
  }
};

export const deleteObject = (key) => {
  try {
    localStorage.removeItem(key);
  } catch (error) {
    console.error("Delete " + key + " failed due to " + error)
  }
  return null;
};

export const setWithExpiry = (key, value, ttl) => {
  const now = new Date();

  // `item` is an object which contains the original value
  // as well as the time when it's supposed to expire
  const item = {
    value: value,
    expiry: now.getTime() + ttl
  };
  setObject(key, item);
};

export const getWithExpiry = key => {
  const item = getObject(key);

  // if the item doesn't exist, return null
  if (!item) {
    return null;
  }

  const now = new Date();

  // compare the expiry time of the item with the current time
  if (now.getTime() > item.expiry) {
    // If the item is expired, delete the item from storage
    // and return null
    deleteObject(key);
    return null;
  }
  return item.value;
};