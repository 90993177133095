import * as ShopifyUtils from "../utils/shopify-utils"
import { constructCssClasses, constructCssVars  } from "../utils/css-utils";

const isBrowser       = typeof window !== 'undefined';
const isNativeSupport = isBrowser && window.CSS && window.CSS.supports && window.CSS.supports('(--a: 0)');

/**
 * Finds a the sale object based on product metatag.
 */
export function findSale(sales, productMetatags) {
  let sale = ShopifyUtils.findPreviewSale(sales);
  if (sale) return sale;

  const legacySale = !!productMetatags.SaleEndsAt && !productMetatags.SaleId;
  ShopifyUtils.debugLog("Is legacy sale: " + legacySale);
  if(legacySale) {
    const activeSales = sales.filter(s =>
      s.Status === 1 || s.Status === 2);
    if (activeSales)
      sale = activeSales[0];
  }
  else {
    if (productMetatags.SaleId)
      sale = sales.find(x => x.Id === productMetatags.SaleId)
  }
  return sale;
}

/**
 * Adds a countdown timer box
 */
export function addCountdownTimerBox(sale, productMetatags) {
  const legacySale = !!productMetatags.SaleEndsAt && !productMetatags.SaleId;
  const saleEndsAt = legacySale ? productMetatags.SaleEndsAt : sale.EndDate;

  ShopifyUtils.debugLog("Is legacy sale: " + legacySale);
  ShopifyUtils.debugLog(sale);

  let timerDate = new Date(saleEndsAt);
  if ((sale.Action.ActivateCountdown || legacySale) && timerDate > new Date()) {
    const [timerBox, removeTimerBox] = createCountdownTimerBoxElement(sale.CountdownTimerLayout, saleEndsAt, sale.Id);

    const holder = ShopifyUtils.findCountdownHolderElm();
    if (holder) {
      holder.appendChild(timerBox);
    }
    else {
      const addToCartElm = ShopifyUtils.findAddToCartElement();
      ShopifyUtils.insertAfter(timerBox, addToCartElm);
    }
  }
}

/**
 * Add countdown timer for sales
 */
export function addCountdownTimerForSales(sales) {
  const activeSales = sales.filter(s => ShopifyUtils.isSaleActive(s));
  for(const sale of activeSales) {
    let timerDate = new Date(sale.EndDate);
    if (timerDate > new Date()) {
      const [timerBox, removeTimerBox] = createCountdownTimerBoxElement(sale.CountdownTimerLayout, sale.EndDate, sale.Id);

      const holder = ShopifyUtils.findCountdownHolderElmForSaleId(sale.Id);
      
      if (holder) {
        holder.appendChild(timerBox);
      }
    }
  }
}

/**
 * Creates the countdown timer box element.
 * @param {datetime} endsAt 
 * @param {function} callback 
 */
export function createCountdownTimerBoxElement(countdownTimerLayout, endsAt, saleId, callback = null, className = "disco-flip-clock") {
  const timerBox = document.createElement("div");
  const removeTimerBox = () => { if (timerBox) timerBox.remove(); }

  const timer = new Clock(
    new Date(endsAt),
    callback ? callback : removeTimerBox,
    className
  );

  timerBox.id = "ct_pe_disco";
  timerBox.appendChild(document.createElement("h2"));
  if (timer && timer.el) {
    timerBox.appendChild(timer.el);
  }

  // Handle CountdownTimerLayout
  if (countdownTimerLayout && timer.el) {
    // Add boolean classes to bar element.
    const cssClasses = constructCssClasses(countdownTimerLayout);
    ShopifyUtils.importGoogleFont(countdownTimerLayout.FontText);
    ShopifyUtils.importGoogleFont(countdownTimerLayout.FontFlipNumber);
    ShopifyUtils.debugLog("Adding countdowntimer classes: " + cssClasses);
    timer.el.classList.add(...cssClasses);
    
    // Add css variables for this sale to style in head.
    const cssVarElm = document.createElement("style");
    const cssString = constructCssVars(countdownTimerLayout, "pe-ct");
    const cssVarSelector = isNativeSupport ? `#ct_pe_disco.id${saleId}` : ":root";
    cssVarElm.appendChild(document.createTextNode(`${cssVarSelector} { ${cssString} }`));
    timerBox.classList.add(`id${saleId}`);

    ShopifyUtils.debugLog(cssString);
    document.head.appendChild(cssVarElm);
  }

  return [timerBox, removeTimerBox];
}

/**
 * Creates the countdown timer page element.
 * @param {datetime} endsAt 
 * @param {function} callback 
 */
export function createCountdownTimerElement(countdownTimerLayout, endsAt, saleId, callback = null, className = "disco-flip-clock") {
  const timerBox = document.createElement("div");
  const removeTimerBox = () => { if (timerBox) timerBox.remove(); }

  const timer = new Clock(
    new Date(endsAt),
    callback ? callback : removeTimerBox,
    className
  );
  
  timerBox.id = "pe_flash_sale_ct";
  if (timer && timer.el) {
    timerBox.appendChild(timer.el);
  }

  // Handle CountdownTimerLayout
  if (countdownTimerLayout && timer.el) {
    // Add boolean classes to bar element.
    const cssClasses = constructCssClasses(countdownTimerLayout);
    ShopifyUtils.importGoogleFont(countdownTimerLayout.FontText);
    ShopifyUtils.importGoogleFont(countdownTimerLayout.FontFlipNumber);
    ShopifyUtils.debugLog("Adding countdowntimer classes: " + cssClasses);
    timer.el.classList.add(...cssClasses);
    
    // Add css variables for this sale to style in head.
    const cssVarElm = document.createElement("style");
    const cssString = constructCssVars(countdownTimerLayout, "pe-ct");
    const cssVarSelector = isNativeSupport ? `#pe_flash_sale_ct.id${saleId}` : ":root";
    cssVarElm.appendChild(document.createTextNode(`${cssVarSelector} { ${cssString} }`));
    timerBox.classList.add(`id${saleId}`);

    ShopifyUtils.debugLog(cssString);
    document.head.appendChild(cssVarElm);
  }

  return [timerBox, timer.reattachElm];
}

/**
 * Countdown card for a single digit.
 * @param {string} label 
 * @param {number} value 
 */
function CountdownTracker(label, value, noFlip = false) {
  var el = document.createElement("span");

  el.className = "flip-clock__piece";

  if (noFlip) {
    el.innerHTML =
      '<b class="flip-clock__discoflipcard discoflipcard__noflip"><b class="discoflipcard__noflip__card"></b></b>' +
      '<span class="flip-clock__slot flip-clock__slot_' + label + '" />';

    this.el = el;

    var top = el.querySelector(".discoflipcard__noflip__card");

    this.replaceElm = function (elm) {
      this.el = elm;
      top = elm.querySelector(".discoflipcard__top");
    }

    this.update = function (val) {
      val = ("0" + val).slice(-2);
      if (val !== this.currentValue) {
        this.currentValue = val;
        top.innerText = this.currentValue;
        this.el.classList.remove("discoflip");
        void this.el.offsetWidth;
        this.el.classList.add("discoflip");
      }
    };

    this.update(value);
  }
  else {
    el.innerHTML =
      '<b class="flip-clock__discoflipcard discoflipcard"><b class="discoflipcard__top"></b><b class="discoflipcard__bottom"></b><b class="discoflipcard__back"><b class="discoflipcard__bottom"></b></b></b>' +
      '<span class="flip-clock__slot flip-clock__slot_' + label + '" />';
  
    this.el = el;

    var top = el.querySelector(".discoflipcard__top"),
      bottom = el.querySelector(".discoflipcard__bottom"),
      back = el.querySelector(".discoflipcard__back"),
      backBottom = el.querySelector(
        ".discoflipcard__back .discoflipcard__bottom"
      );

    this.replaceElm = function (elm) {
      this.el = elm;
      top = elm.querySelector(".discoflipcard__top"),
      bottom = elm.querySelector(".discoflipcard__bottom"),
      back = elm.querySelector(".discoflipcard__back"),
      backBottom = elm.querySelector(
        ".discoflipcard__back .discoflipcard__bottom"
      );
    }

    this.update = function (val) {
      val = ("0" + val).slice(-2);
      if (val !== this.currentValue) {
        if (this.currentValue >= 0) {
          back.setAttribute("data-value", this.currentValue);
          bottom.setAttribute("data-value", this.currentValue);
        }
        this.currentValue = val;
        top.innerText = this.currentValue;
        backBottom.setAttribute("data-value", this.currentValue);

        this.el.classList.remove("discoflip");
        void this.el.offsetWidth;
        this.el.classList.add("discoflip");
      }
    };

    this.update(value);
  }
}

/**
 * Calculates time remaining until end time.
 * @param {datetime} endtime 
 * @returns {Total, Days, Hours, Minutes}
 */
function getTimeRemaining(endtime) {
  var t = Date.parse(endtime) - Date.parse(new Date());
  return {
    Total: t,
    Days: Math.floor(t / (1000 * 60 * 60 * 24)),
    Hours: Math.floor((t / (1000 * 60 * 60)) % 24),
    Minutes: Math.floor((t / 1000 / 60) % 60),
    Seconds: Math.floor((t / 1000) % 60)
  };
}

/**
 * Gets current time.
 * @returns {Total, Days, Hours, Minutes}
 */
function getTime() {
  var t = new Date();
  return {
    Total: t,
    Hours: t.getHours() % 12,
    Minutes: t.getMinutes(),
    Seconds: t.getSeconds()
  };
}

/**
 * Adds a countdown timer to an element.
 * @param {booleam} countdown 
 * @param {function} callback 
 * @param {stromg} className 
 */
export default function Clock(countdown, callback, className = "disco-flip-clock", noFlip = false) {
  // Calculation adapted from https://www.sitepoint.com/build-javascript-countdown-timer-no-dependencies/
  countdown = countdown ? new Date(Date.parse(countdown)) : false;
  callback = callback || function () { };

  var updateFn = countdown ? getTimeRemaining : getTime;

  this.el = document.createElement("div");
  this.el.className = className;

  var trackers = {},
    t = updateFn(countdown),
    key,
    timeinterval;

  for (key in t) {
    if (key === "Total") {
      continue;
    }
    if (key === "Days" && t.Days <= 0) {
      continue;
    }
    trackers[key] = new CountdownTracker(key, t[key], noFlip);
    this.el.appendChild(trackers[key].el);
  }

  this.reattachElm = function (elm) {
    var flipclockPieces = elm.querySelectorAll(".flip-clock__piece");
    if (flipclockPieces.length == 4) {
      trackers["Days"].replaceElm(flipclockPieces[0]);
      trackers["Hours"].replaceElm(flipclockPieces[1]);
      trackers["Minutes"].replaceElm(flipclockPieces[2]);
      trackers["Seconds"].replaceElm(flipclockPieces[3]);
    }
    if (flipclockPieces.length == 3) {
      trackers["Hours"].replaceElm(flipclockPieces[0]);
      trackers["Minutes"].replaceElm(flipclockPieces[1]);
      trackers["Seconds"].replaceElm(flipclockPieces[2]);
    }
  }

  var i = 0;
  function updateClock() {
    timeinterval = requestAnimationFrame(updateClock);

    // throttle so it's not constantly updating the time.
    if (i++ % 10) {
      return;
    }

    var t = updateFn(countdown);
    if (t.Total < 0) {
      cancelAnimationFrame(timeinterval);
      for (key in trackers) {
        trackers[key].update(0);
      }
      callback();
      return;
    }
    if (t.Days <= 0 && "Days" in trackers) {
      const tracker = trackers["Days"];
      tracker.el.remove();
      delete trackers["Days"];
    }

    for (key in trackers) {
      trackers[key].update(t[key]);
    }
  }

  setTimeout(updateClock, 500);
}

/* Usage:

// Create countdown timer
var deadline = new Date(Date.parse(new Date()) + 12 * 24 * 60 * 60 * 1000);
var c = new Clock(deadline, function(){ alert('countdown complete') });
document.body.appendChild(c.el);

// Create clock
var clock = new Clock();
document.body.appendChild(clock.el);
*/
