import { ApolloClient, Observable } from '@apollo/client';
import { HttpLink, ApolloLink, InMemoryCache } from '@apollo/client';
import { onError } from '@apollo/client/link/error'
import { RetryLink } from '@apollo/client/link/retry'
import { RestLink } from 'apollo-link-rest';

export function exceedsMaximumCost(cost) {
  const {
    requestedQueryCost,
    actualQueryCost,
    throttleStatus: { maximumAvailable },
  } = cost;
  const requested = actualQueryCost || requestedQueryCost;

  return requested > maximumAvailable;
}

export function calculateDelayCost(cost) {
  const {
    requestedQueryCost,
    actualQueryCost,
    throttleStatus: { currentlyAvailable, restoreRate },
  } = cost;

  const requested = actualQueryCost || requestedQueryCost;
  const restoreAmount = Math.max(0, requested - currentlyAvailable);
  const msToWait = Math.ceil(restoreAmount / restoreRate) * 10000;

  return msToWait;
}

function delay(msToWait) {
  return new Observable((observer) => {
    let timer = setTimeout(() => {
      observer.complete();
    }, msToWait);

    return () => clearTimeout(timer);
  });
}

const errorLink = onError(({ graphQLErrors, networkError, forward, operation, response }) => {
    if (graphQLErrors)
      graphQLErrors.map(({ message, locations, path }) =>
        console.log(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
        ),
      );
    if (networkError) console.log(`[Network error]: ${networkError}`);

    if (response && response.extensions) {
      const cost = response.extensions.cost;

      if (!cost) {
        // We require the cost extension to calculate the delay.
        // Fallback to ApolloClient's own error handler.
        return;
      }
  
      if (exceedsMaximumCost(cost)) {
        // Your query costs more than the maximum allowed cost.
        // Fallback to ApolloClient's own error handler.
        return;
      }
  
      const msToWait = calculateDelayCost(cost);
      operation.setContext({ retry: true, msToWait });
  
      return delay(msToWait).concat(forward(operation));
    }

    return;
  });

const restLink = (defaultRestUri, authQueryString, namedRestEndpoints = {}) => new RestLink({
    uri: defaultRestUri, // apollographql docs: "If you don't specify an endpoint in your query, the default endpoint (the one you specify in the uri option) will be used."
    endpoints: namedRestEndpoints,
    fetchOptions: {
      mode: "cors"
    },
    headers: {
      Authorization: authQueryString
    }
});

const httpLink = (graphQlUri, authQueryString) => new HttpLink({
    uri: graphQlUri,
    fetchOptions: {
      mode: "cors"
    },
    headers: {
      Authorization: authQueryString
    }
  });

const retryLink = new RetryLink({
  delay: {
    initial: 300,
    max: Infinity,
    jitter: true
  },
  attempts: {
    max: 5,
    retryIf: (error, _operation) => !!error
  }
});

export const createClient = (graphQlUri, defaultRestUri, authQueryString, namedRestEndpoints = {}) => new ApolloClient({
  link: ApolloLink.from([
    errorLink,
    restLink(defaultRestUri, authQueryString, namedRestEndpoints),
    retryLink,
    httpLink(graphQlUri, authQueryString)
  ]),
  cache: new InMemoryCache()
});
