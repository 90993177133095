import React from 'react';

const LinkButton = ({ href, onClick, children }) => {
    return (
        <a
            className="Polaris-Button Polaris-Button--pressable Polaris-Button--variantPrimary Polaris-Button--sizeMedium Polaris-Button--textAlignCenter"
            href={href}
            onClick={onClick}
            target="_blank"
            rel="noopener noreferrer"
        >
            <span className="Polaris-Text--root Polaris-Text--bodySm Polaris-Text--medium">
                {children}
            </span>
        </a>
    );
};

export default LinkButton;