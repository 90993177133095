import React from "react";
import { Redirect } from "@shopify/app-bridge/actions";
import { useAppBridge, Loading } from "@shopify/app-bridge-react";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export default function ExitIFrame({redirectUrl}) {
    const app = useAppBridge();
    const { search } = useLocation();

    useEffect(() => {
        redirectIframe(redirectUrl, app);
    }, [app, search, redirectUrl]);
    
    return <Loading />;
}

export function redirectIframe(redirectUrl, app) {
    if (!!app) {
        const redirect = Redirect.create(app);
        redirect.dispatch(
            Redirect.Action.REMOTE,
            decodeURIComponent(redirectUrl)
        );
    }
    else {
        console.error(`Cannot redirect to ${redirectUrl} because app is undefined`);
    }
}

export function redirectLocal(redirectUrl, app) {
    if (!!app) {
        const redirect = Redirect.create(app);
        redirect.dispatch(
            Redirect.Action.APP,
            decodeURIComponent(redirectUrl)
        );
    }
    else {
        console.error(`Cannot redirect to ${redirectUrl} because app is undefined`);
    }
}