import React from 'react';
import {
  Button,
  BlockStack,
  Text,
  InlineStack,
  Icon,
  Box
} from "@shopify/polaris";
import {
  ChatMajor,
  PlayCircleMajor,
  ReadTimeMinor
} from "@shopify/polaris-icons";
import DismissableBanner from "./DismissableBanner";
import { openBeacon } from "../../utils/BeaconUtils";
import LinkButton from '../LinkButton/LinkButton';


const HelpBanner = ({openSupportBeacon = () => {}}) => {
    return (
        <DismissableBanner propName={"needHelpDashboard"} expiryHrs={168} icon={ChatMajor}>
            <BlockStack gap={100}>
                <Text variant="bodyMd" as="span" tone="bold">Need help?</Text>
                <Text variant="bodyMd" as="span" tone="subdued">
                    <Button variant="plain" onClick={openBeacon}><span className="live-chat-link">Contact us via in-app live chat</span></Button> or email at <a href="mailto:support@productessentials.app">support@productessentials.app</a>. We respond to live chat on weekdays from 9 AM to 5 PM CET. For other times, we aim to reply within 24 hours.
                </Text>
                <Box paddingBlockStart={400} paddingBlockEnd={200}>
                    <InlineStack wrap={true} gap="400" align="space-around">
                        <BlockStack gap="200">
                        <Icon source={PlayCircleMajor} />
                        <LinkButton href="//productessentials.app/getting-started">Watch Tutorial Video</LinkButton>
                        <Text variant="bodySm" as="p">Learn the basics in 5 minutes</Text>
                        </BlockStack>
                        <BlockStack gap="200">
                        <Icon source={ReadTimeMinor} />
                        <LinkButton href="//productessentials.helpscoutdocs.com/article/45-creating-a-sale-with-widgets-and-landing-page">Read Sale Creation Guide</LinkButton>
                        <Text variant="bodySm" as="p">Detailed step-by-step instructions</Text>
                        </BlockStack>
                        <BlockStack gap="200">
                        <Icon source={ChatMajor} />
                        <Button onClick={openSupportBeacon}>Contact Support</Button>
                        <Text variant="bodySm" as="p">Get help from our team</Text>
                        </BlockStack>
                    </InlineStack>
                </Box>
            </BlockStack>
        </DismissableBanner>
    );
};

export default HelpBanner;
