import { PositionTypes } from "./PositionTypes";

export const DefaultTheme = {
    ContentDays: "Days",
    ContentHours: "Hrs",
    ContentMinutes: "Mins",
    ContentSeconds: "Secs",
    MarginElements: 4,
    Font: "Chewy",
    FontFlipNumber: "Chewy",
    SizeText: 19,
    SizeFlipNumber: 18,
    SizeFlipLabelText: 12,
    ColorText: "#FFD600",
    ColorBackground: "#262626",
    ColorButton: "#FFD600",
    ColorButtonText: "#262626",
    ImageBackground: "",
    ColorFlipBottom: "#100C08",
    ColorFlipTop: "#100C08",
    ColorFlipNumber: "#FFD600",
    ColorFlipLabelText: "#FFD600",
    SizeFlipCornerRadius: 4,
    Position: PositionTypes.TopPushSiteDown
}

export const Themes = {
    
    BlueOcean: {
        ...DefaultTheme,
        Font: "Julius Sans One",
        FontFlipNumber: "Roboto",
        SizeText: 21,
        SizeFlipNumber: 20,
        SizeFlipLabelText: 12,
        ColorText: "#E0F7FA",
        ColorTextRight: "#E0F7FA",
        ColorBackground: "#001B2E",
        ColorButton: "#00A5E5",
        ColorButtonText: "#FFFFFF",
        ColorFlipBottom: "#00A5E5",
        ColorFlipTop: "#0070F7",
        ColorFlipNumber: "#FFFFFF",
        ColorFlipLabelText: "#E0F7FA",
        SizeFlipCornerRadius: 6,
    },

    MidnightOcean: {
        ...DefaultTheme,
        Font: "Lilita One",
        FontFlipNumber: "Lilita One",
        SizeText: 21,
        SizeFlipNumber: 18,
        SizeFlipLabelText: 12,
        ColorText: "#ffffff",
        ColorTextRight: "#ffffff",
        ColorBackground: "#002540",
        ColorButton: "#0070f7",
        ColorButtonText: "#ffffff",
        ImageBackground: "",
        ColorFlipBottom: "#0070f7",
        ColorFlipTop: "#0070f7",
        ColorFlipNumber: "#ffffff",
        ColorFlipLabelText: "#ffffff",
        SizeFlipCornerRadius: 4,
        Position: PositionTypes.TopPushSiteDown
    },

    ElectricPurple: {
        ...DefaultTheme,
        Font: "Exo",
        FontFlipNumber: "Roboto Mono",
        SizeText: 21,
        SizeFlipNumber: 20,
        SizeFlipLabelText: 12,
        ColorText: "#FFFFFF",
        ColorTextRight: "#FFFFFF",
        ColorBackground: "#4A0E4E",
        ColorButton: "#00FFFF",
        ColorButtonText: "#4A0E4E",
        ColorFlipBottom: "#8A2BE2",
        ColorFlipTop: "#9932CC",
        ColorFlipNumber: "#FFFFFF",
        ColorFlipLabelText: "#FFFFFF",
        SizeFlipCornerRadius: 8,
    },

    TropicalParadise: {
        ...DefaultTheme,
        Font: "Pacifico",
        FontFlipNumber: "Roboto",
        SizeText: 21,
        SizeFlipNumber: 20,
        SizeFlipLabelText: 12,
        ColorText: "#FFFFFF",
        ColorTextRight: "#FFFFFF",
        ColorBackground: "#FF6B6B",
        ColorButton: "#4ECDC4",
        ColorButtonText: "#FFFFFF",
        ColorFlipBottom: "#FFA07A",
        ColorFlipTop: "#FF8C69",
        ColorFlipNumber: "#FFFFFF",
        ColorFlipLabelText: "#FFFFFF",
        SizeFlipCornerRadius: 10,
    },

    CyberNeon: {
        ...DefaultTheme,
        Font: "Righteous",
        FontFlipNumber: "Roboto Mono",
        SizeText: 21,
        SizeFlipNumber: 20,
        SizeFlipLabelText: 12,
        ColorText: "#39FF14",
        ColorTextRight: "#39FF14",
        ColorBackground: "#0D0D0D",
        ColorButton: "#FF10F0",
        ColorButtonText: "#0D0D0D",
        ColorFlipBottom: "#FF10F0",
        ColorFlipTop: "#39FF14",
        ColorFlipNumber: "#FFFFFF",
        ColorFlipLabelText: "#FFFFFF",
        SizeFlipCornerRadius: 0,
    },

    SunsetBliss: {
        ...DefaultTheme,
        Font: "Pacifico",
        FontFlipNumber: "Roboto",
        SizeText: 21,
        SizeFlipNumber: 20,
        SizeFlipLabelText: 12,
        ColorText: "#FF6B6B",
        ColorTextRight: "#FF6B6B",
        ColorBackground: "#FFD166",
        ColorButton: "#FF6B6B",
        ColorButtonText: "#FFFFFF",
        ColorFlipBottom: "#4ECDC4",
        ColorFlipTop: "#45B7D1",
        ColorFlipNumber: "#FFFFFF",
        ColorFlipLabelText: "#FF6B6B",
        SizeFlipCornerRadius: 6,
    },

    GalacticAdventure: {
        ...DefaultTheme,
        Font: "Space Mono",
        FontFlipNumber: "Space Mono",
        SizeText: 21,
        SizeTextMobile: 12,
        SizeFlipNumber: 20,
        SizeFlipLabelText: 12,
        ColorText: "#E0FFFF",
        ColorTextRight: "#E0FFFF",
        ColorBackground: "#191970",
        ColorButton: "#FF69B4",
        ColorButtonText: "#191970",
        ColorFlipBottom: "#4B0082",
        ColorFlipTop: "#8A2BE2",
        ColorFlipNumber: "#FFFFFF",
        ColorFlipLabelText: "#E0FFFF",
        SizeFlipCornerRadius: 8,
    },

    CandyLand: {
        ...DefaultTheme,
        Font: "Bubblegum Sans",
        FontFlipNumber: "Lilita One",
        SizeText: 24,
        SizeFlipNumber: 20,
        SizeFlipLabelText: 12,
        ColorText: "#926bab",
        ColorTextRight: "#926bab",
        ColorBackground: "#FFAFCC",
        ColorButton: "#BDE0FE",
        ColorButtonText: "#000000",
        ColorFlipBottom: "#FFC8DD",
        ColorFlipTop: "#BDE0FE",
        ColorFlipNumber: "#000000",
        ColorFlipLabelText: "#926bab",
        SizeFlipCornerRadius: 4,
    },

    EarthyVibes: {
        ...DefaultTheme,
        Font: "Chewy",
        FontFlipNumber: "Roboto",
        SizeText: 24,
        SizeFlipNumber: 20,
        SizeFlipLabelText: 12,
        ColorText: "#FFFFFF",
        ColorTextRight: "#FFFFFF",
        ColorBackground: "#228B22",
        ColorButton: "#FFD700",
        ColorButtonText: "#228B22",
        ColorFlipBottom: "#8B4513",
        ColorFlipTop: "#A0522D",
        ColorFlipNumber: "#FFFFFF",
        ColorFlipLabelText: "#FFFFFF",
        SizeFlipCornerRadius: 5,
    },

    RetroWave: {
        ...DefaultTheme,
        Font: "Righteous",
        FontFlipNumber: "Roboto Mono",
        SizeText: 24,
        SizeFlipNumber: 20,
        SizeFlipLabelText: 12,
        ColorText: "#00FFFF",
        ColorTextRight: "#00FFFF",
        ColorBackground: "#000080",
        ColorButton: "#FF00FF",
        ColorButtonText: "#000080",
        ColorFlipBottom: "#00FFFF",
        ColorFlipTop: "#FF00FF",
        ColorFlipNumber: "#FFFFFF",
        ColorFlipLabelText: "#FFFFFF",
        SizeFlipCornerRadius: 0,
    },

    CitrusZest: {
        ...DefaultTheme,
        Font: "Courgette",
        FontFlipNumber: "Roboto",
        SizeText: 24,
        SizeFlipNumber: 20,
        SizeFlipLabelText: 12,
        ColorText: "#FFFFFF",
        ColorTextRight: "#FFFFFF",
        ColorBackground: "#32CD32",
        ColorButton: "#FF4500",
        ColorButtonText: "#FFFFFF",
        ColorFlipBottom: "#FF4500",
        ColorFlipTop: "#FFA500",
        ColorFlipNumber: "#FFFFFF",
        ColorFlipLabelText: "#FFFFFF",
        SizeFlipCornerRadius: 12,
    },

    ArcticAurora: {
        ...DefaultTheme,
        Font: "Julius Sans One",
        FontFlipNumber: "Roboto",
        SizeText: 21,
        SizeFlipNumber: 20,
        SizeFlipLabelText: 12,
        ColorText: "#FFFFFF",
        ColorTextRight: "#FFFFFF",
        ColorBackground: "#191970",
        ColorButton: "#7CFC00",
        ColorButtonText: "#191970",
        ColorFlipBottom: "#7CFC00",
        ColorFlipTop: "#00FFFF",
        ColorFlipNumber: "#FFFFFF",
        ColorFlipLabelText: "#FFFFFF",
        SizeFlipCornerRadius: 8,
    },

    BlackFriday1: {
        ...DefaultTheme,
        Font: "Chewy",
        FontFlipNumber: "Chewy",
        SizeText: 19,
        SizeFlipNumber: 18,
        SizeFlipLabelText: null,
        ColorText: "#FFD600",
        ColorTextRight: "#FFD600",
        ColorBackground: "#262626",
        ColorButton: "#FFD600",
        ColorButtonText: "#262626",
        ImageBackground: "",
        ColorFlipBottom: "#100C08",
        ColorFlipTop: "#100C08",
        ColorFlipNumber: "#FFD600",
        ColorFlipLabelText: "#FFD600",
        SizeFlipCornerRadius: 4,
    },

    BlackFriday2: {
        ...DefaultTheme,
        Font: "Righteous",
        FontFlipNumber: "Anton",
        SizeText: 21,
        SizeFlipNumber: 20,
        SizeFlipLabelText: 12,
        ColorText: "#FFD700",
        ColorTextRight: "#FFD700",
        ColorBackground: "#1A1A1A",
        ColorButton: "#FFD700",
        ColorButtonText: "#1A1A1A",
        ColorFlipBottom: "#2C2C2C",
        ColorFlipTop: "#3C3C3C",
        ColorFlipNumber: "#FFD700",
        ColorFlipLabelText: "#FFD700",
        SizeFlipCornerRadius: 4,
    },

    CyberMonday: {
        ...DefaultTheme,
        Font: "Roboto Mono",
        FontFlipNumber: "Roboto Mono",
        SizeText: 21,
        SizeFlipNumber: 20,
        SizeFlipLabelText: 12,
        ColorText: "#00FFFF",
        ColorTextRight: "#00FFFF",
        ColorBackground: "#1A1A2E",
        ColorButton: "#FF00FF",
        ColorButtonText: "#1A1A2E",
        ColorFlipBottom: "#00FFFF",
        ColorFlipTop: "#FF00FF",
        ColorFlipNumber: "#FFFFFF",
        ColorFlipLabelText: "#FFFFFF",
        SizeFlipCornerRadius: 0,
    },

    BoxingDaySales: {
        ...DefaultTheme,
        Font: "Anton",
        FontFlipNumber: "Roboto",
        SizeText: 24,
        SizeFlipNumber: 20,
        SizeFlipLabelText: 12,
        ColorText: "#FFFFFF",
        ColorTextRight: "#FFFFFF",
        ColorBackground: "#D32F2F",
        ColorButton: "#4CAF50",
        ColorButtonText: "#FFFFFF",
        ColorFlipBottom: "#4CAF50",
        ColorFlipTop: "#66BB6A",
        ColorFlipNumber: "#FFFFFF",
        ColorFlipLabelText: "#FFFFFF",
        SizeFlipCornerRadius: 8,
    },

    AmazonPrimeDay: {
        ...DefaultTheme,
        Font: "Alegreya SC",
        FontFlipNumber: "Roboto",
        SizeText: 21,
        SizeFlipNumber: 20,
        SizeFlipLabelText: 12,
        ColorText: "#232F3E",
        ColorTextRight: "#232F3E",
        ColorBackground: "#FF9900",
        ColorButton: "#232F3E",
        ColorButtonText: "#FFFFFF",
        ColorFlipBottom: "#232F3E",
        ColorFlipTop: "#37475A",
        ColorFlipNumber: "#FFFFFF",
        ColorFlipLabelText: "#FFFFFF",
        SizeFlipCornerRadius: 4,
    },

    SinglesDay: {
        ...DefaultTheme,
        Font: "Righteous",
        FontFlipNumber: "Roboto",
        SizeText: 21,
        SizeFlipNumber: 20,
        SizeFlipLabelText: 12,
        ColorText: "#FFFFFF",
        ColorTextRight: "#FFFFFF",
        ColorBackground: "#E60012",
        ColorButton: "#FFD700",
        ColorButtonText: "#E60012",
        ColorFlipBottom: "#FFD700",
        ColorFlipTop: "#FFA500",
        ColorFlipNumber: "#FFFFFF",
        ColorFlipLabelText: "#FFFFFF",
        SizeFlipCornerRadius: 11,
    },

    BackToSchoolSales: {
        ...DefaultTheme,
        Font: "Spectral",
        FontFlipNumber: "Roboto",
        SizeText: 23,
        SizeFlipNumber: 20,
        SizeFlipLabelText: 12,
        ColorText: "#FFFFFF",
        ColorTextRight: "#FFFFFF",
        ColorBackground: "#3F51B5",
        ColorButton: "#FFC107",
        ColorButtonText: "#3F51B5",
        ColorFlipBottom: "#FFC107",
        ColorFlipTop: "#FFD54F",
        ColorFlipNumber: "#FFFFFF",
        ColorFlipLabelText: "#FFFFFF",
        SizeFlipCornerRadius: 6,
    },

    LaborDaySales: {
        ...DefaultTheme,
        Font: "Libre Franklin",
        FontFlipNumber: "Roboto",
        SizeText: 21,
        SizeFlipNumber: 20,
        SizeFlipLabelText: 12,
        ColorText: "#FFFFFF",
        ColorTextRight: "#FFFFFF",
        ColorBackground: "#0D47A1",
        ColorButton: "#FFFFFF",
        ColorButtonText: "#0D47A1",
        ColorFlipBottom: "#B71C1C",
        ColorFlipTop: "#D32F2F",
        ColorFlipNumber: "#FFFFFF",
        ColorFlipLabelText: "#FFFFFF",
        SizeFlipCornerRadius: 4,
    },

    MemorialDaySales: {
        ...DefaultTheme,
        Font: "Playfair Display",
        FontFlipNumber: "Roboto",
        SizeText: 21,
        SizeFlipNumber: 20,
        SizeFlipLabelText: 12,
        ColorText: "#FFFFFF",
        ColorTextRight: "#FFFFFF",
        ColorBackground: "#B71C1C",
        ColorButton: "#FFFFFF",
        ColorButtonText: "#B71C1C",
        ColorFlipBottom: "#0D47A1",
        ColorFlipTop: "#1976D2",
        ColorFlipNumber: "#FFFFFF",
        ColorFlipLabelText: "#FFFFFF",
        SizeFlipCornerRadius: 4,
    },

    NewYearsSales: {
        ...DefaultTheme,
        Font: "Kaushan Script",
        FontFlipNumber: "Roboto",
        SizeText: 27,
        SizeFlipNumber: 22,
        SizeFlipLabelText: 12,
        ColorText: "#FFFFFF",
        ColorTextRight: "#FFFFFF",
        ColorBackground: "#000000",
        ColorButton: "#FFD700",
        ColorButtonText: "#000000",
        ColorFlipBottom: "#FFD700",
        ColorFlipTop: "#FFA500",
        ColorFlipNumber: "#FFFFFF",
        ColorFlipLabelText: "#FFFFFF",
        SizeFlipCornerRadius: 10,
    },

    ChineseNewYearSales: {
        ...DefaultTheme,
        Font: "Mr Dafoe",
        FontFlipNumber: "Roboto",
        SizeText: 27,
        SizeFlipNumber: 20,
        SizeFlipLabelText: 12,
        ColorText: "#FFD700",
        ColorTextRight: "#FFD700",
        ColorBackground: "#C41E3A",
        ColorButton: "#FFD700",
        ColorButtonText: "#C41E3A",
        ColorFlipBottom: "#FFD700",
        ColorFlipTop: "#FFA500",
        ColorFlipNumber: "#FFFFFF",
        ColorFlipLabelText: "#FFFFFF",
        SizeFlipCornerRadius: 8,
    },

    DiwaliSales: {
        ...DefaultTheme,
        Font: "Pacifico",
        FontFlipNumber: "Roboto",
        SizeText: 24,
        SizeFlipNumber: 20,
        SizeFlipLabelText: 12,
        ColorText: "#FFD700",
        ColorTextRight: "#FFD700",
        ColorBackground: "#800080",
        ColorButton: "#FFA500",
        ColorButtonText: "#800080",
        ColorFlipBottom: "#FFA500",
        ColorFlipTop: "#FFB700",
        ColorFlipNumber: "#FFFFFF",
        ColorFlipLabelText: "#FFFFFF",
        SizeFlipCornerRadius: 15,
    },

    EasterSales: {
        ...DefaultTheme,
        Font: "Courgette",
        FontFlipNumber: "Roboto",
        SizeText: 24,
        SizeFlipNumber: 20,
        SizeFlipLabelText: 12,
        ColorText: "#FFFFFF",
        ColorTextRight: "#FFFFFF",
        ColorBackground: "#9B59B6",
        ColorButton: "#F1C40F",
        ColorButtonText: "#9B59B6",
        ColorFlipBottom: "#F1C40F",
        ColorFlipTop: "#F39C12",
        ColorFlipNumber: "#FFFFFF",
        ColorFlipLabelText: "#FFFFFF",
        SizeFlipCornerRadius: 12,
    },

    HalloweenSales: {
        ...DefaultTheme,
        Font: "Creepster",
        FontFlipNumber: "Bubblegum Sans",
        SizeText: 27,
        SizeFlipNumber: 22,
        SizeFlipLabelText: 12,
        ColorText: "#FF6600",
        ColorTextRight: "#FF6600",
        ColorBackground: "#1A1A1A",
        ColorButton: "#FF6600",
        ColorButtonText: "#1A1A1A",
        ColorFlipBottom: "#663399",
        ColorFlipTop: "#9932CC",
        ColorFlipNumber: "#FF6600",
        ColorFlipLabelText: "#FF6600",
        SizeFlipCornerRadius: 0,
    },

    ValentinesDaySales: {
        ...DefaultTheme,
        Font: "Pacifico",
        FontFlipNumber: "Roboto",
        SizeText: 21,
        SizeFlipNumber: 20,
        SizeFlipLabelText: 12,
        ColorText: "#FF69B4",
        ColorTextRight: "#FF69B4",
        ColorBackground: "#FFF0F5", // Hot Pink
        ColorButton: "#FF1493", // Deep Pink
        ColorButtonText: "#FFFFFF",
        ColorFlipBottom: "#FF1493", // Medium Violet Red
        ColorFlipTop: "#FF69B4", // Pale Violet Red
        ColorFlipNumber: "#FFFFFF",
        ColorFlipLabelText: "#FF69B4",
        SizeFlipCornerRadius: 6,
    },

    SummerSales: {
        ...DefaultTheme,
        Font: "Baloo",
        FontFlipNumber: "Roboto",
        SizeText: 24,
        SizeFlipNumber: 20,
        SizeFlipLabelText: 12,
        ColorText: "#FFFFFF",
        ColorTextRight: "#FFFFFF",
        ColorBackground: "#FF6347",
        ColorButton: "#FFD700",
        ColorButtonText: "#FF6347",
        ColorFlipBottom: "#FFA500",
        ColorFlipTop: "#FFD700",
        ColorFlipNumber: "#FFFFFF",
        ColorFlipLabelText: "#FFFFFF",
        SizeFlipCornerRadius: 10,
    },
};

