import React from 'react'
import reactCSS from 'reactcss'
import { SketchPicker } from 'react-color'
import { Popover } from '@shopify/polaris'

class ColorPicker extends React.Component {
  static defaultProps = {
    color: "#000",
    onUpdateColor: (hex) => null
  }

  state = {
    displayColorPicker: false
  };

  handleClick = () => {
    this.setState({ displayColorPicker: !this.state.displayColorPicker })
  };

  handleClose = () => {
    this.setState({ displayColorPicker: false })
  };

  handleChange = (color) => {
    this.props.onUpdateColor(color.hex);
  };

  render() {
    const color = this.props.color ? this.props.color : "";

    const styles = reactCSS({
      'default': {
        color: {
          width: '36px',
          height: '34px',
          borderRadius: '1px',
          border: '0',
          backgroundColor: `${ color }`
        },
        swatch: {
          background: '#fff',
          borderRadius: '2px',
          border: '0.1rem solid #C4CDD5',
          //boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
          display: 'inline-block',
          cursor: 'pointer',
        }
      },
    });

    const activator = (
      <div style={ styles.swatch } onClick={ this.handleClick }>
        <div style={ styles.color } />
      </div>
    );

    return (
      <div>
        <Popover
          active={this.state.displayColorPicker}
          activator={activator}
          onClose={this.handleClose}
        >
          <SketchPicker color={color} disableAlpha={true} onChange={ this.handleChange } />
        </Popover>
      </div>
    )
  }
}

export default ColorPicker