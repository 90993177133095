import React, { useState, useEffect, useCallback } from "react";
import { useAppBridge, Loading } from "@shopify/app-bridge-react";
import { useParams } from "react-router-dom";
import { SubscriptionPlan } from "./SubscriptionPlan";
import axios from "axios";
import Config from "../../utils/Config";
import { redirectIframe } from "../../components/ExitIFrame/ExitIFrame";
import { Card, Button, BlockStack, Text, Page} from "@shopify/polaris";

const performInstallOp = async (authQueryString, app, host) => {
    const installUrl = `${Config.apiGateway.URL}/authentication/install/${Config.appName}${authQueryString}`;
    const installStatus = await axios.get(installUrl);

    if (installStatus.status === 200) {
        if (installStatus.data.Plan === SubscriptionPlan.LegacyStarter) {
            // Refresh app with new plan.
            //redirectLocal("/", app);
            redirectIframe(`https://${host}`, app);
        } else {
            // Redirect to charge activation page.
            redirectIframe(installStatus.data.RedirectUrl, app);
        }
    }
};

export function UpdatePermissionsRoute({ host, authQueryString }) {
    const app = useAppBridge();

    useEffect(() => {
        performInstallOp(`?updatePermissions=true&${authQueryString}`, app, host);
    }, [app, authQueryString, host]);

    return <Loading />;
}

export function ChangeSubscriptionRoute({ host, authQueryString }) {
    const app = useAppBridge();
    const params = useParams();
    const [subscriptionStatus, setSubscriptionStatus] = useState("unset");
    const [errorMessage, setErrorMessage] = useState("");

    const performSubscriptionOp = useCallback(async (targetPlan) => {
        const upgradeDowngradeUrl = `${Config.apiGateway.URL}/subscription/${Config.appName}/change`;
        
        try {
            const response = await axios.post(upgradeDowngradeUrl, {
                TargetPlan: targetPlan
            }, {
                headers: {
                    Authorization: authQueryString
                }
            });

            if (response.data.Success) {
                if (response.data.RedirectUrl) {
                    // Redirect to charge activation page.
                    redirectIframe(response.data.RedirectUrl, app);
                } else {
                    // Refresh app with new plan.
                    redirectIframe(`https://${host}`, app);
                }
            } else {
                setSubscriptionStatus("failed");
                setErrorMessage(response.data.Message || "Subscription change failed.");
            }
        } catch (error) {
            setSubscriptionStatus("failed");
            setErrorMessage(error.message || "An error occurred during subscription change.");
        }
    }, [host, app, authQueryString]);

    useEffect(() => {
        if (params.targetPlan) {
            const targetPlanValue = parseInt(params.targetPlan, 10);
            if (Object.values(SubscriptionPlan).includes(targetPlanValue) && 
                targetPlanValue !== SubscriptionPlan.Inactive) {
                performSubscriptionOp(targetPlanValue);
            } else {
                setSubscriptionStatus("failed");
                setErrorMessage(`Invalid or default target plan: ${params.targetPlan}`);
            }
        } else {
            setSubscriptionStatus("failed");
            setErrorMessage("No target plan specified");
        }
    }, [params.targetPlan, host, authQueryString, app, performSubscriptionOp]);

    if (subscriptionStatus === "unset") {
        return <Loading />;
    }

    if (subscriptionStatus === "failed") {
        return (
            <Page>
                <Card background="bg-surface-critical">
                    <BlockStack gap={400}>
                        <Text variant="headingMd">Failed changing subscription</Text>
                        <Text variant="bodyMd">{errorMessage}</Text>
                        <Button url="/subscription" variant="primary">
                            Select another subscription plan
                        </Button>
                    </BlockStack>
                </Card>
            </Page>
        );
    }

    return <Loading />;
}
