import React from "react";
import { ButtonGroup, Button, Text, BlockStack } from "@shopify/polaris";
import DismissableBanner from "../Banners/DismissableBanner";
import { openBeacon } from "../../utils/BeaconUtils";
import LinkButton from "../LinkButton/LinkButton";
import { StarOutlineMinor } from "@shopify/polaris-icons";
import { setPropnameDismissed } from "../Banners/DismissableBanner";

const FeedbackCard = ({paddingStart = 0, paddingEnd = 0}) => {
  const propName = "feedbackCard";
  const expiryHrs = 700;

  return (
    <DismissableBanner propName={propName} expiryHrs={expiryHrs} paddingStart={paddingStart} paddingEnd={paddingEnd} icon={StarOutlineMinor}>
        <BlockStack gap={200}>
            <Text variant="bodyMd" as="span" tone="bold">We value your feedback!</Text>
            <Text variant="bodyMd" as="span">
                Your feedback helps us improve and lets others know what to expect. We'd love to hear your thoughts!
            </Text>
            <ButtonGroup>
                <LinkButton onClick={() => setPropnameDismissed(propName, expiryHrs)} href="https://apps.shopify.com/discount-app#modal-show=WriteReviewModal">
                    <span role="img" aria-label="heart">❤️</span> Love the App!
                </LinkButton>
                <Button
                    onClick={() => {
                        openBeacon();
                        setPropnameDismissed(propName, expiryHrs);
                    }} 
                    variant="monochromePlain">
                    <span role="img" aria-label="light bulb">💡</span> Needs Improvements
                </Button>
            </ButtonGroup>
        </BlockStack>
    </DismissableBanner>
  );
};

export default FeedbackCard;
