import React, { Suspense, lazy, useState, useMemo, useEffect } from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { Navigate } from "react-router";
import { AppProvider, Frame, Loading } from "@shopify/polaris";
import { AppProvider as DiscountsProvider } from '@shopify/discount-app-components';
import { Provider, NavigationMenu } from "@shopify/app-bridge-react";
import { ApolloProvider } from "@apollo/client";
import { createClient } from "./graphql/Client";
import Config from "./utils/Config";
import { SubscriptionPlan } from "./pages/SubscriptionPage/SubscriptionPlan";
import { AdapterLink } from "./utils/ShopifyEmbeddedAppRoute";
import "./App.css";
import "@shopify/polaris/build/esm/styles.css";
import "@shopify/discount-app-components/build/esm/styles.css";
import "@blueprintjs/core/lib/css/blueprint.css";
import "@blueprintjs/datetime2/lib/css/blueprint-datetime2.css";
import ExitIFrame from "./components/ExitIFrame/ExitIFrame";
import {
  ChangeSubscriptionRoute,
  UpdatePermissionsRoute,
} from "./pages/SubscriptionPage/SubscriptionRoutes";
import DashboardPage from "./pages/DashboardPage/DashboardPage";
import AnnouncementBarPage from "./pages/WidgetsPage/AnnouncementBarPage";
import { setBeaconStore } from "./utils/BeaconUtils";

const graphQlUri = `${Config.apiGateway.URL}/shopifyGraphQL`;
const authQueryString = "?" + window.location.search.split("?")[1];
const urlParams = new URLSearchParams(window.location.search);
const shopOrigin = urlParams.get("shop");
const host = urlParams.get("host");
const locale = urlParams.get("locale");

const client = createClient(graphQlUri, Config.apiGateway.URL, authQueryString, { discoFlyApi: Config.flyApi.URL });

// Pages
const ReviewPage = lazy(() => import("./pages/ReviewPage/ReviewPage"));
const CampaignWizard = lazy(() => import("./pages/CampaignWizardPage/CampaignWizard"));
const VatNumberPage = lazy(() => import("./pages/VatPage/VatNumberPage"));
const ReauthPage = lazy(() => import("./pages/ReauthPage/ReauthPage"));
const SubscriptionPage = lazy(() => import("./pages/SubscriptionPage/SubscriptionPage"));
const FaqPage = lazy(() => import("./pages/FaqPage/FaqPage"));
const WidgetsPage = lazy(() => import("./pages/WidgetsPage/WidgetsPage"));
const CountdownTimerPage = lazy(() => import("./pages/WidgetsPage/CountdownTimerPage"));
const SettingsPage = lazy(() => import("./pages/SettingsPage/SettingsPage"));
const CampaignsPage = lazy(() => import("./pages/CampaignsPage/CampaignsPage"));

function App({
  plan = SubscriptionPlan.LegacyStarter,
  freeDiscountsLeft = 0,
  requestVatNumber = false,
  redirectUrl = "",
  missingScopes = [],
  onboardingStatus = {},
}) {
  const navigate = useNavigate();
  const location = useLocation();
  const history = useMemo(
    () => ({ replace: (path) => navigate(path, { replace: true }) }),
    [navigate]
  );

  const router = useMemo(
    () => ({
      location,
      history,
    }),
    [location, history]
  );

  useEffect(() => {
    window.$crisp=[];
    window.CRISP_WEBSITE_ID="912570fe-6df6-4689-aa83-0bf215d879e6";
    const script = document.createElement("script");

    script.src = "https://client.crisp.chat/l.js";
    script.async = true;

    document.head.appendChild(script);

    setBeaconStore(shopOrigin);
  }, [])

  const [dismissedVatPage] = useState(false);
  const [dismissedReauthPage, setDismissedReauthPage] = useState(false);
  const anySubdestinationsMatching = (link, pathname) => {
    const subdestinations = {
      Campaigns: ["/create", "/edit"],
    };
    const linkSubdestinations = subdestinations.Campaigns;
    if (linkSubdestinations && Array.isArray(linkSubdestinations)) {
      return linkSubdestinations.some((x) => pathname.startsWith(x));
    }
    return false;
  };

  const config = {
    apiKey: Config.apiKey,
    shopOrigin: shopOrigin,
    host: host,
    forceRedirect: false,
    appEmbedUuid: Config.appEmbedUuid,
  };

  return (
    <AppProvider
      linkComponent={AdapterLink}
      features={{ newDesignLanguage: true }}
    >
      <DiscountsProvider locale="en-US" ianaTimezone="America/Los_Angeles">
        <Provider config={config} router={router}>
          <NavigationMenu
            navigationLinks={[
              {
                label: "Campaigns",
                destination: "/status",
              },
              {
                label: "Widgets",
                destination: "/widgets",
              },
              {
                label: "Settings",
                destination: "/settings",
              },
              {
                label: "Plan",
                destination: "/subscription",
              },
              {
                label: "FAQ",
                destination: "/faq",
              },
            ]}
            matcher={(link, location) =>
              link.destination === location.pathname ||
              anySubdestinationsMatching(link, location.pathname)
            }
          />
          <ApolloProvider client={client}>
            {redirectUrl !== "" && <ExitIFrame redirectUrl={redirectUrl} />}
            {redirectUrl === "" && (
              <Suspense fallback={<Frame><Loading /></Frame>}>
                <Routes>
                  <Route
                    path="/dashboard"
                    element={
                      <DashboardPage
                        plan={plan}
                        storeUrl={shopOrigin}
                        appEmbedUuid={config.appEmbedUuid}
                        {...onboardingStatus}
                      />
                    }
                  />
                  <Route path="/review" element={<ReviewPage />} />
                  <Route
                    path="/create/:copyId?"
                    element={
                      <CampaignWizard
                        locale={locale}
                        storeUrl={shopOrigin}
                        plan={plan}
                      />
                    }
                  />
                  <Route
                    path="/edit/:id"
                    element={
                      <CampaignWizard
                        locale={locale}
                        storeUrl={shopOrigin}
                        plan={plan}
                      />
                    }
                  />
                  <Route
                    path="/settings"
                    element={
                      <SettingsPage
                        locale={locale}
                        storeUrl={shopOrigin}
                        appName={Config.appName}
                      />
                    }
                  />
                  <Route
                    path="/subscription"
                    element={
                      <SubscriptionPage
                        locale={locale}
                        storeUrl={shopOrigin}
                        appName={Config.appName}
                        freeDiscountsLeft={freeDiscountsLeft}
                        plan={plan}
                      />
                    }
                  />
                  <Route
                    path="/subscription/change/:targetPlan"
                    element={
                      <ChangeSubscriptionRoute
                        authQueryString={authQueryString}
                        host={window.atob(host)}
                      />
                    }
                  />
                  <Route
                    path="/widgets"
                    element={
                      <WidgetsPage
                        locale={locale}
                        storeUrl={shopOrigin}
                        appName={Config.appName}
                      />
                    }
                  />
                  <Route
                    path="/widgets/countdown-timer"
                    element={
                      <CountdownTimerPage 
                        appName={Config.appName} 
                        onBack={() => navigate("/widgets")}
                      />
                    }
                  />
                  <Route
                    path="/widgets/announcement-bar"
                    element={
                      <AnnouncementBarPage 
                        appName={Config.appName} 
                        onBack={() => navigate("/widgets")}
                      />
                    }
                  />
                  <Route path="/faq" element={<FaqPage />} />
                  <Route
                    path="/vat"
                    element={<VatNumberPage appName={Config.appName} />}
                  />
                  <Route
                    path="/status"
                    element={
                      <CampaignsPage
                        locale={locale}
                        storeUrl={shopOrigin}
                        plan={plan}
                      />
                    }
                  />
                  <Route
                    path="/reauth"
                    element={
                      <ReauthPage
                        laterPressed={dismissedReauthPage}
                        setLaterPressed={setDismissedReauthPage}
                      />
                    }
                  />
                  <Route
                    path="/update-permissions"
                    element={
                      <UpdatePermissionsRoute
                        authQueryString={authQueryString}
                        host={window.atob(host)}
                      />
                    }
                  />
                  <Route
                    path="/"
                    element={
                      <Home
                        requestVatNumber={requestVatNumber}
                        dismissedVatPage={dismissedVatPage}
                        missingScopes={missingScopes}
                      />
                    }
                  />
                </Routes>
              </Suspense>
            )}
          </ApolloProvider>
        </Provider>
      </DiscountsProvider>
    </AppProvider>
  );
}

function AppWrapper(props) {
  return (
    <BrowserRouter>
      <App {...props} />
    </BrowserRouter>
  );
}

function Home({
  requestVatNumber = true,
  dismissedVatPage = false,
  missingScopes = [],
}) {
  if (requestVatNumber && !dismissedVatPage) {
    return <Navigate to="/vat" />;
  } else if (missingScopes && missingScopes.length > 0) {
    return <Navigate to="/reauth" />;
  } else {
    return <Navigate to="/dashboard" />;
  }
}

export default AppWrapper;
