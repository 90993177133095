import gql from "graphql-tag";

export function GET_SETTINGS(appName) {
  return gql`
    query GetSettings {
      settings @rest(type: "CountdownTimerSettings", path: "/${appName}/settings", method: "GET") {
        SnippetInstalled
        HasActiveSales
        RevertMode
        DiscountMode
      }
    }
  `;
}

export function GET_DESIGN_SETTINGS(appName) {
  return gql`
    query GetSettings {
      settings @rest(type: "CountdownTimerSettings", path: "/${appName}/settings/design", method: "GET") {
        CountdownTimerSettings
        AnnouncementBarSettings
      }
    }
  `;
}

export function GET_ONBOARDING_STATUS() {
  return gql`
    query GetAppBlockStatus {
      onboardingStatus @rest(type: "CountdownTimerSettings", path: "/onboarding/status", method: "GET") {
        HasCreatedFirstDiscount
        HasCustomizedDesign
        HasDismissedOnboarding
        HasInstalledAppEmbed
        HasModifiedAppBlockPlacement
        HasModifiedAppEmbedFeaturedCollections
      }
    }
  `;
}


export function GET_GETTING_STARTED_STATUS(appName) {
  return gql`
    query GetSettings {
      gettingStartedStatus @rest(type: "GettingStartedStatus", path: "/${appName}/settings/gettingStartedStatus", method: "GET") {
        HasCreatedFirstDiscount
        HasCustomizedCountdown
        HasDismissedBanner
      }
    }
  `;
}
